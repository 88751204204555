import LoadingGif from '../images/loader.gif'

function showLoading() {
  var loadingImage = document.createElement('img');
  loadingImage.setAttribute('src', LoadingGif);
  loadingImage.setAttribute('alt', 'Loading...');
  loadingImage.style.position = 'absolute';
  loadingImage.style.left = '50%';
  loadingImage.style.top = '50%';
  loadingImage.style.transform = 'translate(-50%, -50%)';
  document.body.appendChild(loadingImage);
}

export { showLoading };
