// app/javascript/packs/application.js

// Import dependencies
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

// Import npm packages and dependencies
import "jquery"
// import "wishart"
import $ from "jquery"
import "jquery-ui"

import "@popperjs/core"
import "datatables.net-dt"
import "d3"
import "rickshaw"
import "select2"
import "bootstrap"
import { Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";

// import "leaflet/dist/leaflet.css"
// import 'leaflet'
// import L from 'leaflet';
// //import "bootstrap/dist/js/bootstrap.min.js"
// // import "bootstrap/dist/css/bootstrap.min.css"
// import "font-awesome/css/font-awesome.min.css"
// import "leaflet.awesome-markers/dist/leaflet.awesome-markers.css"
// import "leaflet.awesome-markers/dist/leaflet.awesome-markers.js"
// // import 'leaflet.awesome-markers/dist/leaflet.awesome.rotate.min.css';
// import "leaflet-timedimension"
// //import "leaflet-timedimension/dist/leaflet.timedimension.min.css"
// //import "leaflet-timedimension/dist/leaflet.timedimension.min.js"
// import "leaflet.fullscreen"
// //import "leaflet.fullscreen/dist/Control.FullScreen.min.css"
// //import "leaflet.fullscreen/dist/Control.FullScreen.min.js"
// import "highlight.js/styles/default.css"
// import moment from "moment"
// import "iso8601-js-period"
// import 'jquery-ui-dist/jquery-ui';
// // import 'folium/folium.css';
// import 'stylesheets/leaflet-awesome-rotate.css';

//import 'https://cdn.jsdelivr.net/gh/python-visualization/folium/folium/templates/leaflet.awesome.rotate.min.css';


// Import local JS files
import Plotly from "plotly.js-dist-min"
import "controllers"
import "stylesheets/application"

import "packs/animals"
import "packs/commodity"
import "packs/equipment"
import "packs/commodity_plot"
import "packs/current_weather_plots"
import "packs/calf_mortality_prediction"
//import "packs/leaflet.js"
import "vendor/Rickshaw.Graph.Renderer.LinePlot"
import "vendor/Rickshaw.Graph.Renderer.Multi"
import commodity_plot_plotly from "packs/commodity_plot"
import breakeven_prices_plotly from "packs/simulation_plotly"
// import {sixty_mins_precipitation_plotly, hourly_plotly, daily_plotly_temp, daily_plotly_sunMoon, daily_plotly} from "packs/current_weather_plots"
import { showLoading } from "packs/loading"
import { subscribeToCurrentWeatherForecast } from "packs/current_weather_plots"
// import "packs/weather_helpers"
// import "packs/weather"  // DO NOT import here. Import in views using javascript_pack_tag
import L from 'leaflet';
import './leaflet-openweathermap'

console.log("Hello from application.js", Plotly)

// Initialize dependencies
Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Plotly as a global variable
window.Plotly = Plotly;
// window.chartkick = chartkick;
window.jQuery = $;
window.$ = $;
window.commodity_plot_plotly = commodity_plot_plotly;
window.breakeven_prices_plotly = breakeven_prices_plotly;
window.showLoading = showLoading;
window.subscribeToCurrentWeatherForecast = subscribeToCurrentWeatherForecast;


document.addEventListener("turbolinks:load", function() {
    $('[data-toggle="popover"]').popover()
})

// window.sixty_mins_precipitation_plotly = sixty_mins_precipitation_plotly
// window.hourly_plotly = hourly_plotly
// window.daily_plotly_temp = daily_plotly_temp
// window.daily_plotly_sunMoon = daily_plotly_sunMoon
// window.daily_plotly = daily_plotly 

// window.L = L;
// window.moment = moment;

// import "packs/dashboard/dashboard"

// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"


// require("jquery")
// require('@popperjs/core')
// //import "bootstrap-sprockets"
// require('bootstrap')
// require("datatables.net-dt")
// //require("datatables.net-bs3")
// require('d3')
// require("rickshaw")

// import "controllers"
// import "stylesheets/application"
// import "chartkick/chart.js"

// import "packs/animals"
// import "packs/commodity"
// import "packs/equipment"
// import "packs/simulation_plotly"
// import "packs/commodity_plot"

// import "vendor/Rickshaw.Graph.Renderer.LinePlot"
// import "vendor/Rickshaw.Graph.Renderer.Multi"
// import Plotly from "vendor/plotly.min"

// Rails.start()
// Plotly.start()
// Turbolinks.start()
// ActiveStorage.start()



