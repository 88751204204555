function breakeven_prices_plotly(divID, data) {

    var trace1 = {
        x: Object.keys(data[0]),
        y: Object.values(data[0]),
        type: 'bar',
        textposition: 'auto',
        // text: Object.values(data[0]),
        marker: {
            color: data[1],
            opacity: 0.6,
            line: {
                color: 'black',
                width: 1
            }
        }
    }

    var data = [trace1]

    var layout = {
        barmode: 'stack',
        yaxis: {
            title: {
                text: "CAD/lb"
            }
        },
        margin: {
            t: 30,
            b: 20,
            l: 100
        },
        // hovermode: false,
        plot_bgcolor: "#d8e0e8"
    }
    var config = {
        scrollZoom: true,
        displayModeBar: true,
        displaylogo: false,
        responsive: true,
        modeBarButtonsToRemove: ["editinchartstudio"]
    }

    Plotly.newPlot(divID, data, layout, config);
}


function summary_expenses_plotly(divID, data) {

    text = []
    Object.keys(data).forEach((key) => {
        t = `${key}: CAD ${data[key]}`
        text.push(t)
    })
    console.log("here: ", text)
    plotly_data = [{
        type: 'pie',
        values: Object.values(data),
        labels: Object.keys(data),
        text: text,
        textinfo: "text",
        textposition: "outside",
        automargin: true,
        hole: 0.5,
        marker: {
            line: {
                width: 5,
                color: 'white'
            }
        },
        hoverinfo: 'label+percent'
    }]

    layout = {
        showlegend: false,
        margin: {
            t: 30,
            b: 30,
            l: 30,
            r: 30
        }
    }
    config = {
        scrollZoom: true,
        displayModeBar: true,
        displaylogo: false,
        responsive: true
    }

    Plotly.newPlot(divID, plotly_data, layout, config)

}

function detail_expenses_plotly(divID, data) {

    text = [[], [], [], []]
    i = 0
    data.forEach((item) => {
        Object.keys(item).forEach((key) => {
            t = `${key}: CAD ${item[key]}`
            text[i].push(t)
        })
        i = i+ 1
    })
    plotly_data = [{
        type: 'pie',
        name: "Feed (per day)",
        values: Object.values(data[0]),
        labels: Object.keys(data[0]),
        text: text[0],
        textinfo: "text",
        textposition: "inside",
        automargin: true,
        rotation: 290,
        hoverinfo: 'label+percent+name',
        insidetextorientation: 'radial',
        domain: { column: 0 },
        textfont: {
            color: 'white'
        },
        marker: {
            line: {
                width: 2,
                color: 'white'
            }
        }
    }, {
        type: 'pie',
        name: "Debt Service (per head)",
        values: Object.values(data[1]),
        labels: Object.keys(data[1]),
        text: text[1],
        textinfo: "text",
        textposition: "inside",
        automargin: true,
        rotation: 290,
        insidetextorientation: 'horizontal',
        hoverinfo: 'label+percent+name',
        domain: { column: 1 },
        textfont: {
            color: 'white'
        },
        marker: {
            line: {
                width: 2,
                color: 'white'
            }
        }
    }, {
        type: 'pie',
        name: "Risk Margin (per head)",
        values: Object.values(data[2]),
        labels: Object.keys(data[2]),
        text: text[2],
        textinfo: "text",
        textposition: "inside",
        automargin: true,
        rotation: 290,
        insidetextorientation: 'radial',
        hoverinfo: 'label+percent+name',
        domain: { column: 2 },
        textfont: {
            color: 'black'
        },
        marker: {
            line: {
                width: 2,
                color: 'white'
            }
        }
    }, {
        type: 'pie',
        name: "Selling (per head)",
        values: Object.values(data[3]),
        labels: Object.keys(data[3]),
        text: text[3],
        textinfo: "text",
        textposition: "outside",
        automargin: true,
        rotation: 272,
        insidetextorientation: 'radial',
        hoverinfo: 'label+percent+name',
        domain: { column: 3 },
        textfont: {
            color: 'black'
        },
        marker: {
            line: {
                width: 2,
                color: 'white'
            }
        }
    }]

    //iterate dynamically over piecharts and put the name as subtitle of the plot
    var annotations = [];
    var xPerTrace = 1 / plotly_data.length;
    var startX = 0;
    for (var i = 0; i < plotly_data.length; i++) {
        //set domain of
        plotly_data[i].domain = {
            x: [startX, startX + xPerTrace],
            y: [0.1, 1]
        }

        //create annotation for it
        annotations.push({
            font: {
                size: 20,
                color: 'black'
            },
            xanchor: 'left',
            yanchor: 'bottom',
            xref: 'paper',
            showarrow: false,
            text: plotly_data[i].name,
            x: startX+0.04,
            y: 0//,
            //bgcolor: "rgba(255,33,22,0)"
        });

        //increase startX
        startX += xPerTrace;
    }
    annotations[0].x += 0.02
    annotations[1].x -= 0.015
    annotations[2].x -= 0.01
    annotations[3].x += 0.01

    layout = {
        showlegend: false,
        titlefont: {
            size: 24
        },
        margin: {
            t: 25,
            b: 25,
            r: 75,
            l: 25,
        },
        grid: {
            rows: 1,
            columns: 4
        },
        annotations: annotations
    }

    config = {
        scrollZoom: true,
        displayModeBar: true,
        displaylogo: false,
        responsive: true
    }

    Plotly.newPlot(divID, plotly_data, layout, config)
}


function plotly_bkeven_detail(divID, data, title) {
    all_markers = ['#438c91', '#826be8', '#ed7253']
    markers = []
    for (var i=0; i<Object.keys(data).length; i++) {
        randomElement = all_markers[Math.floor(Math.random() * all_markers.length)]
        markers.push(randomElement)
    }
    data = [
        {
            x: Object.keys(data),
            y: Object.values(data),
            type: 'bar',
            marker: {
                color: markers,
                opacity: 0.6,
                line: {
                    color: 'black',
                    width: 1
                }
            }
        }
    ]

    layout = {
        title: {
            text: title,
            xref: 'paper',
            x: 0.05
        },
        plot_bgcolor: "#d8e0e8",
        margin: {
            t:50,
            b:20,
            l:25,
            r:25
        }
    }

    config = {
        scrollZoom: true,
        displayModeBar: true,
        displaylogo: false,
        responsive: true
    }

    Plotly.newPlot(divID, data, layout, config)
}

export default breakeven_prices_plotly;
