var commodity_plot = function(id, data, container, slider_id, y_label, frequency='daily'){

  $(id).empty()
  var padding    = 40;
  var axis_width = 40;
  var y_limit = 100; //Max y value, we scale to 100 as this is convention
  var height = 600; // Graph height
  options = typeof options !== 'undefined' ? options : {};

  freq_buffer = 0
  switch(frequency) {
  case 'minutes':

    break;
  case 'daily':
    freq_buffer = 1000
  case 'weekly':
    break;
  case 'monthly':

    break;
  case 'quarterly':

    break;
  case 'yearly':

    break;
  default:
    freq_buffer = 1000
  }

  // set up the html markup
  var chart_container = d3.select(id),
  chart_container_element = $(container)[0];
  chart_container.attr('class','chart_container');
  chart_container.append('div').attr('class','y_axis_label').text(y_label);
  chart_container.append('div').attr('class', 'x_axis_label').text("Time");
  var y_axis_element = chart_container.append('div').attr('class','y_axis')[0][0],
    chart_element  = chart_container.append('div').attr('class','chart')[0][0],
    x_axis_element = chart_container.append('div').attr('class','x_axis')[0][0];
  // chart_container.append('div').attr('class','x_axis_label').text('TimeStamp');

  // This figures out the chart container size so we know how big to draw the graph
  var get_width = function() {
    return chart_container_element.offsetWidth - 2*padding;
  }

  var ymax = y_limit;
  var ymin = 0

  var xmax = Number.MIN_VALUE;
  var xmin = Number.MAX_VALUE;
  for (i = 0; i < data.length; i++) {
    xmin = Math.min(xmin, data[i].x);
    xmax = Math.max(xmax, data[i].x);
  }



  var width = get_width()-300;
  height = height;

  var graph = new Rickshaw.Graph( {
    element: chart_element,
    width: width,
    height: height,
    xmin: xmin,
    renderer: 'line',
    series: [
      {
        color: '#d17e1a',
        data: data.results_high,
        name: 'High'
      }, {
          color: '#003b17',
          data: data.results_low,
          name: 'Low'
        }
    ]
  } );

  graph.xMin = options.xMin ||    0;
  graph.xMax = options.xMax || xmax+10;
  graph.yMin = options.yMin || 0;
  graph.yMax = options.yMax || ymax+10;

  var slider = new Rickshaw.Graph.RangeSlider.Preview({
    graph: graph,
    element: document.querySelector(slider_id)
  });

  // slider.update()

  new Rickshaw.Graph.Axis.Y( {
    graph: graph,
    orientation: 'left',
    tickFormat: Rickshaw.Fixtures.Number.formatKMBT,
    ticks: 5,
    element: y_axis_element,
    grid: true,
    height: height
  } );

  new Rickshaw.Graph.Axis.X({
    graph: graph,
    orientation: 'bottom',
    // tickFormat: function(x){return x.toPrecision(3)},
    element: x_axis_element,
    tickFormat: function(x){
      return new Date(x * freq_buffer).toLocaleDateString();
    },
    grid: false
  })

  new Rickshaw.Graph.HoverDetail(
    {
    graph: graph,
    formatter: function(series, x, y,formattedX, formattedY, d) {
      return "<div class=\"hover-box\" style=\"width: 150px;\">" + "Date: " + new Date(x * freq_buffer).toLocaleDateString() + "<br>Price: " + y.toFixed(2) + "</div>";
    }
  });


  // new Rickshaw.Graph.Axis.X( {
  //   graph: graph,
  //   orientation: 'bottom',
  //   element: x_axis_element,
  //   grid: false
  // } );



  graph.render();

  // data = data_temp

  // var padding    = 15;
  // var axis_width = 40;
  // var y_limit = 100; //Max y value, we scale to 100 as this is convention
  // var height = 250; // Graph height
  // options = typeof options !== 'undefined' ? options : {};

  // // set up the html markup
  // var chart_container = d3.select(id),
  //     chart_container_element = $(container)[0];
  // chart_container.attr('class','chart_container');
  // chart_container.append('div').attr('class','y_axis_label').text('Intensity');
  // var y_axis_element = chart_container.append('div').attr('class','y_axis')[0][0],
  //   chart_element  = chart_container.append('div').attr('class','chart')[0][0],
  //   x_axis_element = chart_container.append('div').attr('class','x_axis')[0][0];
  // chart_container.append('div').attr('class','x_axis_label').text('Retention Time (min)');

  // // This figures out the chart container size so we know how big to draw the graph
  // var get_width = function() {
  //   return chart_container_element.offsetWidth - 2*padding - axis_width;
  // }


  // // Sort the spectra on x value for Rickshaw
  // var sort_data = function(a, b) {
  //   return ((a.x < b.x) ? -1 : ((a.x > b.x) ? 1 : 0));
  // }

  // data.sort(sort_data)

  // // // Calculate the min and max y-values
  // var ymax = y_limit;
  // var ymin = 0;

  // var xmax = Number.MIN_VALUE;
  // var xmin = Number.MAX_VALUE;
  // for (i = 0; i < data.length; i++) {
  //   xmin = Math.min(xmin, data[i].x);
  //   xmax = Math.max(xmax, data[i].x);
  // }


  // var width = get_width();

  // // Now draw the graph
  // var graph = new Rickshaw.Graph( {
  //   element: chart_element,
  //   width: width,
  //   height: height,
  //   xmin: 0,
  //   renderer: 'multi',
  //   name: 'Low',
  //   series:
  //   [{ name: 'Full',
  //     color: '#003b17',
  //     renderer: 'line',
  //     data: data
  //   },
  //   { name: 'High',
  //   color: '#d17e1a',
  //   rmin: 5,
  //   renderer: 'line',
  //   data: data_final
  // }]
  // } );



  // graph.xMin = options.xMin ||    0;
  // graph.xMax = options.xMax || xmax+10;
  // graph.yMin = options.yMin ||    0;
  // graph.yMax = options.yMax || ymax+1000;

  // new Rickshaw.Graph.Axis.Y( {
  //   graph: graph,
  //   orientation: 'left',
  //   tickFormat: Rickshaw.Fixtures.Number.formatKMBT,
  //   ticks: 10,
  //   element: y_axis_element,
  //   grid: false,
  //   height: height
  // } );

  // new Rickshaw.Graph.Axis.X( {
  //   graph: graph,
  //   orientation: 'bottom',
  //   tickFormat: function(x){return x.toPrecision(3)},
  //   element: x_axis_element,
  //   grid: false
  // } );

  // // new Rickshaw.Graph.HoverDetail(
  // //   {
  // //   graph: graph,
  // //   formatter: function(series, x, y,formattedX, formattedY, d) {
  // //     return "<div class=\"hover-box\" style=\"width: 150px;\">" + "Retention Time: " + x.toFixed(4) + "<br>Intensity: " + y.toFixed(2) + "</div>";
  // //   }
  // // });

  // graph.render();

}

var commodity_plot_small = function (id, data, container) {
  $(id).empty()
  var padding = 2;
  var axis_width = 20;
  var y_limit = 100; //Max y value, we scale to 100 as this is convention
  var height = 250; // Graph height
  options = typeof options !== 'undefined' ? options : {};

  // set up the html markup
  var chart_container = d3.select(id),
  chart_container_element = $(container)[0];
  chart_container.attr('class', 'chart_container_small');
  // chart_container.append('div').attr('class', 'y_axis_label').text(y_label);
  // chart_container.append('div').attr('class', 'x_axis_label').text("Time");
  // var y_axis_element = chart_container.append('div').attr('class', 'y_axis_small')[0][0],
    chart_element = chart_container.append('div').attr('class', 'chart_small')[0][0],
    x_axis_element = chart_container.append('div').attr('class', 'x_axis_small')[0][0];

  // This figures out the chart container size so we know how big to draw the graph
  var get_width = function () {
    return chart_container_element.offsetWidth - 2 * padding;
  }

  var ymax = y_limit;
  var ymin = 0

  var xmax = Number.MIN_VALUE;
  var xmin = Number.MAX_VALUE;
  for (i = 0; i < data.length; i++) {
    xmin = Math.min(xmin, data[i].x);
    xmax = Math.max(xmax, data[i].x);
  }

  var width = get_width();
  height = height;

  var graph = new Rickshaw.Graph({
    element: chart_element,
    width: width,
    height: height,
    xmin: xmin,
    renderer: 'line',
    series: [
      {
        color: '#d17e1a',
        data: data.results_high,
        name: 'High'
      }, {
        color: '#003b17',
        data: data.results_low,
        name: 'Low'
      }
    ]
  });

  graph.xMin = options.xMin || 0;
  graph.xMax = options.xMax || xmax + 10;
  graph.yMin = options.yMin || 0;
  graph.yMax = options.yMax || ymax + 10;

  new Rickshaw.Graph.Axis.Y({
    graph: graph,
    orientation: 'left',
    tickFormat: Rickshaw.Fixtures.Number.formatKMBT,
    ticks: 5,
    element: y_axis_element,
    grid: true,
    height: height
  });

  new Rickshaw.Graph.Axis.X({
    graph: graph,
    orientation: 'bottom',
    // tickFormat: function(x){return x.toPrecision(3)},
    element: x_axis_element,
    tickFormat: function (x) {
      return new Date(x * freq_buffer).toLocaleDateString();
    },
    grid: false
  })

  new Rickshaw.Graph.HoverDetail(
    {
      graph: graph,
      formatter: function (series, x, y, formattedX, formattedY, d) {
        return "<div class=\"hover-box\" style=\"width: 150px;\">" + "Date: " + new Date(x * 1000).toLocaleDateString() + "<br>Price: " + y.toFixed(2) + "</div>";
      }
    });

}



var commodity_plot_plotly = function(id, data, plot_title, y_label, data_type, graph_type, full_width = false) {

  var container_height = document.getElementById(id).offsetHeight
  var container_width = document.getElementById(id).offsetWidth
  if (full_width == false) {
    screenWidth = 0
  } else {
    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    screenWidth *= 0.95
  }

  // function unpack(dataArray, axis) {
  //   res = []
  //   if (axis=='x') {
  //       dataArray.forEach((item) => {
  //           res.push(new Date(item.x))
  //       })
  //   } else if (axis=='y') {
  //       dataArray.forEach((item) => {
  //           res.push(item.y)
  //       })
  //   }
  //   return res
  // }

  // console.log("high:", data.results_high)
  // console.log("low:", data.results_low)
  // console.log("DAY: ", data.results_day)
  // console.log("OPEN: ", data.results_open)
  // console.log("CLOSE: ", data.results_close)

  if (graph_type == "candleStick") {
    var trace = {
      x: data.results_day,
      close: data.results_close,
      high: data.results_high,
      low: data.results_low,
      open: data.results_open,

      // cutomise colors
      increasing: { line: { color: 'green' } },
      decreasing: { line: { color: 'red' } },

      type: 'candlestick',
      xaxis: 'x',
      yaxis: 'y'
    };
    data = [trace]
  } else if (graph_type == "line") {
    var trace_high = {
      type: "scatter",
      mode: "lines",
      name: "High",
      x: data.results_day,
      y: data.results_high,
      line: { color:"#d17e1a" }
    }
    var trace_low = {
      type: "scatter",
      mode: "lines",
      name: "Low",
      x: data.results_day,
      y: data.results_low,
      line: { color: "003b17" }
    }
    data = [trace_high, trace_low]
  }
  // var trace_high = {
  //   type: "scatter",
  //   mode: "lines",
  //   name: 'High',
  //   x: unpack(data.results_high, 'x'),
  //   y: unpack(data.results_high, 'y'),
  //   line: { color: '#d17e1a' }
  // }
  // var trace_low = {
  //   type: "scatter",
  //   mode: "lines",
  //   name: 'Low',
  //   x: unpack(data.results_low, 'x'),
  //   y: unpack(data.results_low, 'y'),
  //   line: { color: '#003b17' }
  // }

  var futuresGraphButtons = [
    {
      count: 1,
      label: '1 Month',
      step: 'month',
      stepmode: 'backward'
    },
    {
      count: 3,
      label: '3 Months',
      step: 'month',
      stepmode: 'backward'
    },
    {
      count: 6,
      label: '6 Months',
      step: 'month',
      stepmode: 'backward'
    },
    {
      count: 1,
      label: '1 Year',
      step: 'year',
      stepmode: 'backward'
    },
    {
      step: 'all',
      label: "All"
    }
  ]

  var historicalGraphButtons = [
    {
      count: 1,
      label: '1 Month',
      step: 'month',
      stepmode: 'backward'
    },
    {
      count: 3,
      label: '3 Months',
      step: 'month',
      stepmode: 'backward'
    },
    {
      count: 6,
      label: '6 Months',
      step: 'month',
      stepmode: 'backward'
    },
    {
      count: 1,
      label: '1 Year',
      step: 'year',
    },
    {
      count: 2,
      label: '2 Years',
      step: 'year',
      stepmode: 'backward'
    },
    {
      count: 3,
      label: '3 Years',
      step: 'year',
      stepmode: 'backward'
    },
    {
      count: 5,
      label: '5 Years',
      step: 'year',
      stepmode: 'backward'
    },
    {
      count: 10,
      label: '10 Years',
      step: 'year',
      stepmode: 'backward'
    },
    {
      count: 20,
      label: '20 Years',
      step: 'year',
      stepmode: 'backward'
    },
    {
      step: 'all',
      label: "All"
    }
  ]

  if (data_type == "historical") var buttons = historicalGraphButtons
  else if (data_type == "futures") var buttons = futuresGraphButtons

  var layout = {
    title: plot_title,
    // autosize: false,
    // height: container_height-((5/100)*container_height),
    // width: container_width-((5/100)*container_width),
    // margin: {
    //   r: 2,
    //   l: 20,
    //   t: 10,
    //   b: 10
    // },
    width: screenWidth,
    plot_bgcolor: "#e5ecf6",
    yaxis: {
      title: { text: y_label },
      ticks: 'outside'
    },
    xaxis: {
      ticks: 'outside',
      rangeslider: {},
      type: 'date',
      rangeselector: {
        buttons: buttons
      }
    }
  }

  var config = {
    scrollZoom: true,
    displayModeBar: true,
    displaylogo: false,
    responsive: true
  }
  Plotly.newPlot(id, data, layout, config)
}

export default commodity_plot_plotly;