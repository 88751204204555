
const loadCommodity = function() {
  //load your variables
  $.ajax({
    url: "/resources/load_commodity",
    type: "GET",
    dataType: "json",
    data: {'start_date': '6edb47cfcd7b156b969bc73cf5d6985b', 'end_date': 'ZWZ22', 'frequency': '', 'symbol': ''},
    success: function(result) {
      // result == json object of new graph
      commodity_plot();
    },
    error: function() {
      alert('Error occurred.');
    }
  });
};

$(document).on('click', function(){
  $('.dropdown-menu').hide();
});

$('.dropdown-toggle').on('click', function(e){
  e.stopPropagation();
  $(this).parent().find('.dropdown-menu').toggle();
});


export default loadCommodity;