import consumer from "../channels/consumer"

let calfMortalityPredictionChannel;

export function subscribeToCalfMortalityPrediction(uuid) {
  calfMortalityPredictionChannel = consumer.subscriptions.create(
    { channel: "CalfMortalityPredictionChannel", uuid: uuid },
    {
      received(data) {
        document.body.querySelector('img').remove();
        document.body.querySelector('img').remove();
        console.log('Received');
        displayResults(data);

        // Make the table visible
        $("#sim-cm-empty-container-prompt").remove();
        $("#sim-cm-show-input-table").css({'visibility':'visible'});

        // Show plotly graphs on prediction analysis
        plotly_mortality();
      },
    }
  );
}

function displayResults(results) {
  // Update the frontend with the job results
  const reshapedResults = reshapeData(results);
  updateTable(reshapedResults);
}

function toCamelCase(str) {
  return str
    .replace(/\s(.)/g, (match) => match.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, (match) => match.toLowerCase());
}

function reshapeData(results) {
  const keys = Object.keys(results);
  const numRows = Object.keys(results[keys[0]]).length;
  console.log(numRows);
  const reshapedData = [];

  for (let i = 0; i < numRows; i++) {
    const rowData = {};
    for (const key of keys) {
      const camelCaseKey = toCamelCase(key);
      rowData[camelCaseKey] = results[key][i.toString()];
    }
    reshapedData.push(rowData);
  }
  return reshapedData;
}

function updateTable(results) {
  // Clear the current table content
  $('#cm-input-table-body').empty();

  // Iterate through the results array and add each item to the table
  for (let i = 0; i < results.length; i++) {
    let newRow = $('<tr>');

    newRow.append(`<td>${i}</td>`);
    newRow.append(`<td>${results[i].sex}</td>`);
    newRow.append(`<td>${results[i].herdID}</td>`);
    newRow.append(`<td>${results[i].twin}</td>`);
    newRow.append(`<td>${results[i].birthDay}</td>`);
    newRow.append(`<td>${results[i].birtyMonth}</td>`);
    newRow.append(`<td>${results[i].birthYear}</td>`);
    newRow.append(`<td>${results[i].birthWeight}</td>`);
    newRow.append(`<td>${results[i].ageOfDam}</td>`);
    newRow.append(`<td>${results[i].temp_1}</td>`);
    newRow.append(`<td>${results[i].avg_humidity_1}</td>`);
    newRow.append(`<td>${results[i].wC_1}</td>`);
    newRow.append(`<td>${results[i].predictedMortality}</td>`);

    $('#cm-input-table-body').append(newRow);
  }
}

function plotly_cm_preds(divID, data) {
  data = [
      {
          type: 'bar',
          y: Object.keys(data),
          x: Object.values(data),
          orientation: 'h',
          marker: {
              color: ['#19cf49', '#f55a42'],
              line: {
                  width: 1,
                  color: 'black'
              }
          }
      }
  ]
  var layout = {
      title: {
          text: "Predicted Alive/Dead Count"
      },
      xaxis: {
          title: {
              text: "Count"
          }
      },
      plot_bgcolor: '#d8e0e8'
  }
  var config = {
      scrollZoom: true,
      displayModeBar: true,
      displaylogo: false,
      responsive: true,
      modeBarButtonsToRemove: ["editinchartstudio"]
  }
  return Plotly.newPlot(divID, data, layout, config)
}

function plotly_cm_distplot(divID, data, factor) {
  var plot_title = (factor == "bweight") ? "Birth Weight Distribution" : "Dam Age Distribution"
  var xaxis_title = (factor == "bweight") ? "Birth Weight (kg)" : "Dam Age (years)"
  var marker_color = (factor == "bweight") ? "#fccb2b" : "#d166f2"
  var data_for_plotly = [
      {
          x: data,
          type: 'histogram',
          marker:{
              color: marker_color,
              line: {
                  width: 1,
                  color: 'black'
              }
          }
      }
  ]

  var layout = {
      title: {
          text: plot_title
      },
      yaxis: {
          title: {
              text: "Count"
          }
      },
      xaxis: {
          title: {
              text: xaxis_title
          }
      },
      plot_bgcolor: '#d8e0e8'
  }
  var config = {
      scrollZoom: true,
      displayModeBar: true,
      displaylogo: false,
      responsive: true,
      modeBarButtonsToRemove: ["editinchartstudio"]
  }

  return Plotly.newPlot(divID, data_for_plotly, layout, config)

}

function plotly_cm_scatter3d(divID, data) {
  var data_for_plotly = [
      {
          name: "Alive",
          x: data[0].Alive,
          y: data[1].Alive,
          z: data[2].Alive,
          mode: 'markers',
          type: 'scatter3d',
        marker: {
              size: 25,
              color: "#19cf49",
              line: {
                  color: 'black',
                  width: 1
              },
              opacity: 0.6
          }
      },
      {
          name: "Dead",
          x: data[0].Dead,
          y: data[1].Dead,
          z: data[2].Dead,
          mode: 'markers',
          type: 'scatter3d',
        marker: {
              size: 25,
              color: '#f55a42',
              line: {
                  color: 'black',
                  width: 1
              },
              opacity: 0.6
          }
      }
  ]

  var layout = {
      scene:{
          xaxis: {
              title: "Birth Weight (kg)",
              backgroundcolor: "rgb(200, 200, 230)",
              gridcolor: "rgb(255, 255, 255)",
              showbackground: true,
              zerolinecolor: "rgb(255, 255, 255)",
          },
          yaxis: {
              title: "Dam Age (years)",
              backgroundcolor: "rgb(230, 200,230)",
              gridcolor: "rgb(255, 255, 255)",
              showbackground: true,
              zerolinecolor: "rgb(255, 255, 255)"
          },
          zaxis: {
              title: "Sex",
              backgroundcolor: "rgb(230, 230,200)",
              gridcolor: "rgb(255, 255, 255)",
              showbackground: true,
              zerolinecolor: "rgb(255, 255, 255)"
          }
      },
      margin: {
          t: 50,
          b: 50,
          r: 10,
          l: 10
      }
  }

  Plotly.newPlot(divID, data_for_plotly, layout)
}


function plotly_mortality() {
  var pred_data = {"Alive": 0, "Dead": 0}
  var corr_marker_color = {"Alive": [], "Dead": []}
  var bweight_data = {"Alive": [], "Dead": []}
  var damAge_data = {"Alive": [], "Dead": []}
  var sex_data = {"Alive": [], "Dead": []}
  var cmTable = document.getElementById("sim-cm-input-table")
  console.log("ROWS: ", cmTable.rows)
  for (var i=1, row; row=cmTable.rows[i]; i++) {
      var prob = row.cells[row.cells.length-1].innerHTML;
      if (prob == "1") {
          row.classList.remove('danger')
          row.classList.add('success')
          row.cells[row.cells.length-1].innerHTML = "<b style='color:green;'>Alive</b>"
          pred_data["Alive"] += 1
          //corr_marker_color.push("#19cf49")
          bweight_data.Alive.push(row.cells[7].innerHTML)
          damAge_data.Alive.push(row.cells[8].innerHTML)
          sex_data.Alive.push(row.cells[1].innerHTML)
      }
      else {
          row.classList.remove('success')
          row.classList.add('danger')
          row.cells[row.cells.length-1].innerHTML = "<b style='color:red;'>Dead</b>"
          pred_data["Dead"] += 1
          //corr_marker_color.push("#f55a42")
          bweight_data.Dead.push(row.cells[7].innerHTML)
          damAge_data.Dead.push(row.cells[8].innerHTML)
          sex_data.Dead.push(row.cells[1].innerHTML)
      }
  }

  plotly_cm_preds("plotly-cm-preds", pred_data);
  plotly_cm_distplot("plotly-cm-bweight", bweight_data["Alive"].concat(bweight_data["Dead"]), "bweight");
  plotly_cm_distplot("plotly-cm-damAge", damAge_data["Alive"].concat(damAge_data["Dead"]), "damAge");
  plotly_cm_scatter3d("plotly-cm-scatter3d", [bweight_data, damAge_data, sex_data]);
}
